export type CurrentBreakpoint =
  | 'small'
  | 'mobile'
  | 'tablet'
  | 'tabletL'
  | 'laptopS'
  | 'laptop'
  | 'desktop';

export const getCurrentBreakpoint =
  (small: boolean, mobile: boolean, tablet: boolean, tabletL: boolean, laptopS: boolean, laptop: boolean, desktop: boolean): CurrentBreakpoint => {

    if (small) {
      return 'small';
    }
    if (mobile) {
      return 'mobile';
    }
    if (tablet) {
      return 'tablet';
    }
    if (tabletL) {
      return 'tabletL';
    }
    if (laptopS) {
      return 'laptopS';
    }
    if (laptop) {
      return 'laptop';
    }
    if (desktop) {
      return 'desktop';
    }

    return 'small';
  };
